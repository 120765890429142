.pagination-container {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-x: auto; /* Ensure the pagination controls are scrollable if they overflow */
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
  }
  
  .page-item.active .page-link {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .page-item.active {
    /* Change the background color and border of the active list item */
    background-color: #0056b3; /* Darker blue */
    border-radius: 4px;
  }
  
  .page-item.active .page-link {
    color: white;
  }
  
  .page-link:hover {
    background-color: #ddd;
  }
  