/* Default active style (dark theme) */
.nav-link.active.light {
    background-color: #212529;
    color: white !important;
    border-radius: 4px;
}

/* Active style for light theme */
.nav-link.active.dark {
    background-color: #FBFBFB;
    color: black !important;
    border-radius: 4px;
}

.navbar-full-height {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.nav-link {
    font-size: 0.84rem !important;
}